<template>
  <div>
    <div class="card" :class="$style.container">
      <div class="text-dark font-size-24 mb-4">
        <strong>Reset Password Request</strong>
      </div>
      <a-form class="mb-4" :form="form">
        <a-form-item>
          <a-input size="large" v-model="user_name" name="user_name" type="text" placeholder="Enter User Name" v-validate="'required'"/></a-form-item>
          <div class="invalid-feedback">Please input your user name!</div>
          <a-button size="large" type="primary" class="text-center w-100" :loading="loader" @click.prevent="forgotPassword"><strong>Reset my password</strong></a-button>
      </a-form>
      <router-link to="/auth/login" class="kit__utils__link font-size-16"><i class="fe fe-arrow-left mr-1 align-middle" />Go to Sign in</router-link>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'CuiForgotPassword',
  data: function () {
    return {
      form: this.$form.createForm(this),
      user_name: '',
      validation_errors: {},
      show: false,
      loader: false,
    }
  },
  methods: {
    forgotPassword() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.post('api/users/forgot-password-request', { user_name: this.user_name })
            .then(response => {
              this.loader = false
              if (!response.data.error) {
                this.$router.push({ name: 'verificationCode', params: { user_name: this.user_name } })
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
              }
            })
            .catch(error => {
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
  },
}
</script>
<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
